import { TranscriptResponseItem } from '@/hooks/useTranscripts';

export function formatTranscriptAsTimeBlocks(sections: TranscriptResponseItem[]) {
  return formatTranscriptAsSentenceBlocks(sections).map((section) => {
    const [first] = section;
    let endTime = first.type === 'pronunciation' ? first.end_time : undefined;
    for (let entryIndex = section.length - 1; entryIndex >= 0; entryIndex--) {
      const entry = section[entryIndex];
      if ('end_time' in entry) {
        endTime = entry.end_time;
        break;
      }
    }

    return {
      entries: formatAsSentences(section),
      start_time: Number(first.type === 'pronunciation' ? first.start_time : undefined),
      end_time: Number(endTime),
    };
  });
}

type Sentence = {
  start_time: number;
  end_time: number;
  content: string;
};

type ArrayType<T> = T extends Array<infer R> ? R : never;

export function formatAsSentences(
  section: ArrayType<ReturnType<typeof formatTranscriptAsSentenceBlocks>>,
) {
  const sentences: Sentence[] = [];
  let currentSentence: string[] = [];
  let startTime: number | null = null;
  let endTime: number | null = null;
  section.forEach((entry, index) => {
    if ('start_time' in entry && !startTime) {
      startTime = Number(entry.start_time);
    }
    if ('end_time' in entry) {
      endTime = Number(entry.end_time);
    }
    // build sentences with the content
    currentSentence.push(entry.alternatives[0].content);

    // if end of sentence or last section
    if (('endOfSentence' in entry && entry.endOfSentence) || !section[index + 1]) {
      const joinedSentence = entry.endOfSentence
        ? currentSentence.splice(0, currentSentence.length - 1).join(' ') // remove punctuation, join sentence
        : currentSentence.join(' ');
      const punctuation = currentSentence[currentSentence.length - 1];
      const content = entry.endOfSentence ? `${joinedSentence}${punctuation}` : joinedSentence;
      sentences.push({
        start_time: startTime!,
        end_time: endTime!,
        content,
      });
      currentSentence = [];
      startTime = null;
    }
  });
  return sentences;
}

function isEndOfSentence(content: string) {
  return content === '.' || content === '?' || content === '!';
}

/**
 * Breakup transcripts into 3-sentence blocks
 */
export function formatTranscriptAsSentenceBlocks(sections: TranscriptResponseItem[]) {
  if (sections.length === 0) {
    return [];
  }
  let numberOfSentences = 0;
  let current: (TranscriptResponseItem & { endOfSentence?: boolean })[] = [];
  const sentences: (typeof current)[] = [];

  sections.forEach((section) => {
    const [{ content }] = section.alternatives;
    const endOfSentence = isEndOfSentence(content);
    if (section.type === 'pronunciation') {
      current.push(section);
    } else if (section.type === 'punctuation' && endOfSentence) {
      numberOfSentences++;
      current.push({
        ...section,
        endOfSentence: true,
      });

      if (numberOfSentences === 3) {
        numberOfSentences = 0;
        sentences.push(current);
        current = [];
      }
    }
  });
  if (current.length > 0) {
    sentences.push(current);
  }
  return sentences;
}

function padNumber(number: number) {
  return `${number}`.padStart(2, '0');
}

export function displaySecondsAsTime(seconds: string | number) {
  let minutesDisplay = '00';
  let secondsDisplay = '00';
  let hoursDisplay = '00';
  const roundedSeconds = Math.round(Number(seconds));
  if (roundedSeconds < 60) {
    secondsDisplay = padNumber(roundedSeconds);
  } else if (roundedSeconds >= 60) {
    let minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds - minutes * 60;
    let hours = 0;
    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);
      hoursDisplay = padNumber(hours);
      minutes -= hours * 60;
    }

    minutesDisplay = padNumber(minutes);
    secondsDisplay = padNumber(remainingSeconds);
  }

  if (hoursDisplay !== '00') {
    return `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`;
  }
  return `${minutesDisplay}:${secondsDisplay}`;
}
