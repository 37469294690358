import React, { useContext } from 'react';
import { MetroContext } from '@bblabs/module-library/classes';
import { extendContext } from '@/helpers/extendContext';

export type EmailReactionsMetroContextType = {
  sendMetric: (event: string) => void;
  MetricNames: {
    transcriptsClose: string;
    transcriptsOpen: string;
    likeClicked: string;
    commentConfirmationCommentAgainClicked: string;
    commentConfirmationTryFreeClicked: string;
  };
};

export const EmailReactionsMetroContext =
  extendContext(MetroContext)<EmailReactionsMetroContextType>();

export const useEmailReactionsMetroContext = () => {
  const ctx = useContext(EmailReactionsMetroContext);
  if (!ctx) {
    throw new Error('useEmailReactionsMetroContext used outside provider');
  }
  return ctx;
};

type EmailReactionsContextType = {
  clientId: string;
  commentReactionUrl: string;
  environment: string;
  firstName: string;
  jobId: string;
  likeReactionUrl: string;
  useCommentFocusMode: boolean;
  videoId: string;
  videoPlayerId: string;
  videoTranscriptUrl: string;
  videoViewId: string;
};

const EmailReactionsContext = React.createContext<EmailReactionsContextType>(null!);

export default EmailReactionsContext;

export const EmailReactionMetricNames = Object.freeze({
  commentButtonClick: 'email_page.comment_button_click',
  commentConfirmationCommentAgainClicked: 'email_page.reaction_comment_again',
  commentConfirmationTryFreeClicked: 'email_page.reaction_tryforfree',
  commentSendWithoutName: 'email_page.comment_no_name',
  likeClicked: 'email_page.like_button_click',
  transcriptsClose: 'click.email_page.transcription.close.button',
  transcriptsOpen: 'click.email_page.transcription.open.button',
  transcriptsClick: 'click.email_page.transcript_sentence',
});
