import React, { useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import { colors, heavy, book } from '@bblabs/module-library/styles';
import { MetroContext } from '@bblabs/module-library/classes';
import { displaySecondsAsTime } from '@/functions/transcripts';
import type { Transcript as TranscriptType } from '@/hooks/useTranscripts';

type TranscriptProps = {
  className?: string;
  videoPlayerId?: string | null;
  currentVideoTime?: number;
  transcript: TranscriptType;
};

/**
 * Display the transcript with highlighting & jump to time.
 */
const Transcript = ({
  className = '',
  transcript,
  currentVideoTime = 0,
  videoPlayerId = null,
}: TranscriptProps) => {
  type TranscriptMetricNames = { transcriptsClick: string };
  const { sendMetric, VIDEO_PAGE_EVENTS, MetricNames } = useContext(MetroContext) as unknown as {
    sendMetric: CallableFunction;
    VIDEO_PAGE_EVENTS: TranscriptMetricNames;
    MetricNames: TranscriptMetricNames;
  };
  const metricNamesForTranscript = MetricNames ?? VIDEO_PAGE_EVENTS;
  const jumpVideoToTime = useCallback((time: number) => {
    sendMetric(metricNamesForTranscript.transcriptsClick);
    const query =
      videoPlayerId === null
        ? 'iframe[title="Video"]'
        : `iframe[data-video-player-id="${videoPlayerId}"]`;
    const modal = document.querySelector(`[role=dialog][data-video-player-id="${videoPlayerId}"]`);
    const container = modal || document;
    container
      .querySelector<HTMLIFrameElement>(query)
      ?.contentWindow?.postMessage(JSON.stringify({ type: 'seek', time, videoPlayerId }), '*');
  }, []);

  return (
    <table className={`${className} transcript`}>
      <tbody>
        {transcript.map((section) => (
          <tr key={section.start_time}>
            <th
              className={
                currentVideoTime > 0 &&
                currentVideoTime >= section.start_time &&
                currentVideoTime <= section.end_time
                  ? 'highlighted'
                  : ''
              }
            >
              {displaySecondsAsTime(section.start_time)}
            </th>
            <td>
              {section.entries.map((sentence) => (
                <React.Fragment key={`${section.start_time}-${sentence.start_time}`}>
                  <span
                    role="button"
                    onClick={() => jumpVideoToTime(sentence.start_time)}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() === 'enter') {
                        jumpVideoToTime(sentence.start_time);
                      }
                    }}
                    tabIndex={0}
                    className={
                      currentVideoTime > 0 &&
                      currentVideoTime >= sentence.start_time - 0.1 &&
                      currentVideoTime < sentence.end_time
                        ? 'highlighted'
                        : ''
                    }
                  >
                    {sentence.content}
                  </span>
                  &nbsp;
                </React.Fragment>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default styled(Transcript)`
  position: relative;

  td,
  th {
    text-align: left;
    vertical-align: top;
  }

  th {
    ${heavy()}
    width: 48px;
    color: ${colors.silverSandAlt};
    &.highlighted {
      color: ${colors.battleship};
    }
  }

  td {
    ${book()};
    color: ${colors.battleship};
    padding-bottom: 25px;
    cursor: pointer;

    span {
      transition: 300ms;
      &.highlighted {
        background-color: ${colors.fog};
      }

      &:hover {
        background-color: ${colors.fog};
      }
    }
    @media (prefers-reduced-motion) {
      span.highlighted {
        background-color: inherit;
      }
    }
  }
`;
