import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { colors } from '@bblabs/module-library/styles';
import classNames from 'classnames';
import SimpleLikeButton from './components/SimpleLikeButton';
import Comment from './components/Comment';
import TranscriptButton from './components/TranscriptButton';
import TranscriptPanel from './components/TranscriptPanel';
import EmailReactionsContext from './context';
import useTranscripts from '@/hooks/useTranscripts';

const validEvents = ['videoTimeWatched', 'videoSecondsWatched', 'videoEnded'];

type EmailReactionsProps = {
  className?: string;
  clientId: string;
  commentReactionUrl: string;
  environment: string;
  firstName?: string | null;
  jobId: string;
  likeReactionUrl: string;
  reactionsEnabled?: boolean;
  smallTranscriptBtn?: boolean;
  videoId: string;
  videoPlayerId: string;
  videoTranscriptUrl?: string | null;
  videoViewId?: string | null;
};

const EmailReactions = ({
  className = '',
  clientId,
  commentReactionUrl,
  environment,
  firstName = null,
  jobId,
  likeReactionUrl,
  reactionsEnabled = true,
  smallTranscriptBtn = false,
  videoId,
  videoPlayerId,
  videoTranscriptUrl = null,
  videoViewId = null,
}: EmailReactionsProps) => {
  /** Time elapsed in seconds (float), used in transcripts */
  const [videoAccurateTimestamp, setVideoAccurateTimestamp] = useState(0);
  const [showTranscript, setShowTranscript] = useState(false);
  const [useCommentFocusMode, setUseCommentFocusMode] = useState(false);
  const [commentIsCollapsed, setCommentIsCollapsed] = useState(true);

  const { transcript, status: transcriptStatus } = useTranscripts(videoTranscriptUrl ?? '');

  useEffect(() => {
    function onMessage(message: MessageEvent) {
      if (!message.isTrusted || message.origin !== window.location.origin) {
        return;
      }

      // get the message data...
      let { data } = message;
      try {
        data = typeof message.data === 'string' ? JSON.parse(message.data) : message.data;
      } catch (error) {
        // intentional
      }

      if (!data) {
        return;
      }

      if (typeof data === 'object' && 'event' in data && validEvents.includes(data.event)) {
        if ('videoPlayerId' in data && videoPlayerId && videoPlayerId !== data.videoPlayerId) {
          console.warn('Received message for a different video player', {
            videoPlayerId,
            messageVideoPlayerId: data.videoPlayerId,
          });
          return;
        }
        if (data.event === 'videoTimeWatched') {
          setVideoAccurateTimestamp(data.time);
        } else if (data.event === 'videoEnded') {
          // reset timestamp
          setVideoAccurateTimestamp(0);
        }
      }
    }
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [videoPlayerId]);

  const context = useMemo(
    () => ({
      clientId,
      commentReactionUrl,
      environment,
      firstName: firstName ?? '',
      jobId,
      likeReactionUrl,
      useCommentFocusMode,
      videoId,
      videoPlayerId,
      videoTranscriptUrl: videoTranscriptUrl ?? '',
      videoViewId: videoViewId ?? '',
    }),
    [
      clientId,
      commentReactionUrl,
      environment,
      firstName,
      jobId,
      likeReactionUrl,
      useCommentFocusMode,
      videoId,
      videoPlayerId,
      videoTranscriptUrl,
      videoViewId,
    ],
  );

  const hasTranscriptions = videoTranscriptUrl !== '';
  return (
    <EmailReactionsContext.Provider value={context}>
      <div
        className={classNames(`email-reactions ${className}`, {
          'has-only-transcripts': hasTranscriptions && !reactionsEnabled,
          'has-only-reactions': reactionsEnabled && !hasTranscriptions,
          transcript: showTranscript,
          'comment-focus-mode': useCommentFocusMode,
        })}
      >
        {!showTranscript && (
          <>
            {reactionsEnabled && <SimpleLikeButton videoTimestamp={videoAccurateTimestamp} />}
            {reactionsEnabled && (
              <Comment
                commentIsCollapsed={commentIsCollapsed}
                setCommentIsCollapsed={setCommentIsCollapsed}
                setUseCommentFocusMode={setUseCommentFocusMode}
                videoTimestamp={videoAccurateTimestamp}
              />
            )}
            {videoTranscriptUrl && (
              <TranscriptButton
                onClick={() => setShowTranscript(true)}
                smallTranscriptBtn={smallTranscriptBtn}
              />
            )}
          </>
        )}
        {showTranscript && (
          <TranscriptPanel
            videoPlayerId={videoPlayerId}
            transcript={transcript}
            status={transcriptStatus}
            currentVideoTime={videoAccurateTimestamp}
            onClose={() => setShowTranscript(false)}
          />
        )}
      </div>
    </EmailReactionsContext.Provider>
  );
};

export default styled(EmailReactions)`
  position: relative;
  margin: 0 24px 0 5px;
  padding: 12px 0;
  background-color: ${colors.white};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  --collapsed-height: 40px;
  --reaction-border-color: ${colors.silverSandAlt};

  &:not(.transcript) {
    display: grid;
    grid-template-columns: ${({ smallTranscriptBtn = false }) =>
      `[like-button] ${smallTranscriptBtn ? '45px' : '56px'} [comment-input] 1fr [transcript-button]
        ${smallTranscriptBtn ? '45px' : '145px'}
      `};
    align-items: start;

    @media screen and (max-width: 480px) {
      grid-template-columns: ${({ smallTranscriptBtn = false }) =>
        `[like-button] 45px [comment-input] 1fr [transcript-button]
          ${smallTranscriptBtn ? '45px' : '120px'}
        `};
    }

    &.has-only-reactions {
      grid-template-columns: [like-button] 56px [comment-input] 1fr;

      @media screen and (max-width: 480px) {
        grid-template-columns: [like-button] 40px [comment-input] 1fr;
      }
    }

    &.has-only-transcripts {
      grid-template-columns: ${({ smallTranscriptBtn = false }) =>
        `[transcript-button]
          ${smallTranscriptBtn ? '45px' : '145px'}
        `};

      @media screen and (max-width: 480px) {
        grid-template-columns: ${({ smallTranscriptBtn = false }) =>
          `[transcript-button]
                    ${smallTranscriptBtn ? '45px' : '120px'}
          `};
      }
    }
  }

  &.comment-focus-mode,
  &.comment-focus-mode.has-only-reactions {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 480px) {
    margin: 0;
  }
`;
