import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { ThumbsUpOutline, ThumbsUpSolid } from '@bblabs/module-library/svgs';
import { colors } from '@bblabs/module-library/styles';
import classNames from 'classnames';
import EmailReactionsContext, { useEmailReactionsMetroContext } from '../context';
import Yardstick from '@/classes/Yardstick';

type SimpleLikeButtonProps = {
  className?: string;
  videoTimestamp?: number;
};

const SimpleLikeButton = ({ className = '', videoTimestamp = 0 }: SimpleLikeButtonProps) => {
  const { likeReactionUrl, useCommentFocusMode, environment } = useContext(EmailReactionsContext);
  const { sendMetric, MetricNames } = useEmailReactionsMetroContext();
  const [highlighted, setHighlighted] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const yardstick = Yardstick.forFeature('reaction', environment);

  function addHighlight() {
    if (disabled) {
      return;
    }
    setHighlighted(true);
  }

  function removeHighlight() {
    if (disabled) {
      return;
    }
    setHighlighted(false);
  }

  async function onClick() {
    yardstick.increment('like.on_player.clicked');
    if (disabled) {
      return;
    }

    yardstick.increment('like.on_player.sent');

    setDisabled(true);
    setHighlighted(true);

    const likeUrl = new URL(likeReactionUrl);
    likeUrl.searchParams.set('timestamp', `${videoTimestamp}`);

    const response = await fetch(likeUrl.toString(), { method: 'post' });
    if (!response.ok) {
      yardstick.increment('like.on_player.failed');
      console.error(await response.text());
      setDisabled(false);
      setHighlighted(false);
      return;
    }

    yardstick.increment('like.on_player.success');
    await sendMetric(MetricNames.likeClicked);
  }

  const likeThisVideo = 'Like this video';
  const likedThisVideo = 'You liked this video';

  return (
    <button
      disabled={disabled}
      className={classNames(className, { 'comment-focus-mode': useCommentFocusMode })}
      type="button"
      onClick={onClick}
      onMouseEnter={addHighlight}
      onMouseLeave={removeHighlight}
      onFocus={addHighlight}
      onBlur={removeHighlight}
      aria-pressed={disabled}
    >
      {!highlighted && <ThumbsUpOutline title={likeThisVideo} fill={colors.almostBlack} />}
      {highlighted && (
        <ThumbsUpSolid title={disabled ? likedThisVideo : likeThisVideo} fill={colors.taosSky} />
      )}
    </button>
  );
};

export default styled(SimpleLikeButton)`
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  padding-top: 11px;
  padding-bottom: 11px;
  border: 1px solid ${colors.white};
  border-radius: 3px;

  &.comment-focus-mode {
    display: none;
  }

  svg {
    width: 17px;
    height: 17px;
  }

  &:disabled svg {
    animation: animate-thumb 600ms 50ms;
    /* span/inline element will not animate unless display: inline-block or similar */
    display: inline-block;

    stroke-width: 0;
  }

  &:focus:not(:disabled) {
    outline: none;
    border: 1px solid ${colors.taosSky};
    box-shadow: 0px 0px 0px 3px ${colors.taosSkyLightest};
  }

  @keyframes animate-thumb {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1);
    }
  }
`;
