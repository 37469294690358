/**
 * Send Yardstick/Grafana metrics
 */
export default class Yardstick {
  featureName: string = null!;

  environment: string = null!;

  static forFeature(featureName: string, environment: string | null) {
    const yardstick = new Yardstick();
    yardstick.featureName = featureName;
    yardstick.setDefaultEnvironment();
    if (environment) {
      yardstick.environment = environment;
    }

    return yardstick;
  }

  increment(metric: string, value = 1) {
    this.post(metric, 'increment', { value: value || 1 });
  }

  timing(metric: string, value: string) {
    this.post(metric, 'timing', { value });
  }

  setDefaultEnvironment() {
    const { hostname } = window.location;
    if (hostname) {
      if (/local\./i.test(hostname)) this.environment = 'local';
      else if (/dev\./i.test(hostname)) this.environment = 'dev';
      else if (/stage\./i.test(hostname)) this.environment = 'stage';
      else this.environment = 'prod';
    }
  }

  async post(metric: string, action: string, additionalData?: Record<string, unknown>) {
    let data = {
      environment: this.environment,
      xsrc: 'app',
    };

    data = Object.assign(data, additionalData ?? {});

    return fetch(`https://yardstick.bombbomb.io/feature/${this.featureName}/${metric}/${action}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).catch((error) => {
      console.error('Yardstick: Error calling metric API', error);
    });
  }
}
