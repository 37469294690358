import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { book, colors, heavy, roman } from '@bblabs/module-library/styles';
import { MetroContext } from '@bblabs/module-library/classes';
import { getLink } from '@/constants/links';

type CommentConfirmationProps = {
  className?: string;
  ctaEnabled?: boolean;
  onCommentAgain: VoidFunction;
};

/**
 * Display a comment sent confirmation with a comment-again button
 *
 * Requires MetroContext to send metrics. Metric names should be specified
 * with MetricNames object, but this also supports VIDEO_PAGE_EVENTS.
 */
const CommentConfirmation = ({
  className = '',
  ctaEnabled = true,
  onCommentAgain,
}: CommentConfirmationProps) => {
  type CommentConfirmationMetricNames = {
    commentConfirmationCommentAgainClicked: string;
    commentConfirmationTryFreeClicked: string;
  };
  const { sendMetric, VIDEO_PAGE_EVENTS, MetricNames } = useContext(MetroContext) as unknown as {
    sendMetric: CallableFunction;
    VIDEO_PAGE_EVENTS: CommentConfirmationMetricNames;
    MetricNames: CommentConfirmationMetricNames;
  };
  const metricEventNames = MetricNames ?? VIDEO_PAGE_EVENTS;

  const metricEvent = (eventName: string) => {
    sendMetric(eventName);
  };

  const handleTryForFree = () => {
    metricEvent(metricEventNames.commentConfirmationTryFreeClicked);
  };

  const handleCommentAgain = () => {
    onCommentAgain();
    metricEvent(metricEventNames.commentConfirmationCommentAgainClicked);
  };

  return (
    <div className={`${className} cta-${ctaEnabled}`}>
      <h3 className="comment-cta-title">Comment Sent!</h3>
      {ctaEnabled && (
        <div className="comment-cta-message">
          Want to send your own videos?
          <a
            className="tryit"
            href={`${getLink('MARKETING_SITE')}?bbsource=pbb-commentcta`}
            onClick={handleTryForFree}
            target="_blank"
            rel="noreferrer"
          >
            Try for free
          </a>
        </div>
      )}
      <button type="button" className="comment-again" onClick={handleCommentAgain}>
        Comment again
      </button>
    </div>
  );
};

export default styled(CommentConfirmation)`
  background-color: ${colors.snow};
  border-radius: 8px;
  color: ${colors.battleship};
  height: 100%;
  letter-spacing: 0px;
  padding-top: 18px;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;

  &.cta-false {
    padding-top: 31px;
    padding-bottom: 30px;
  }

  > .comment-cta-title {
    ${heavy()}
    font-size: 14px;
    margin: 0 0 9px 0;
  }

  > .comment-cta-message {
    ${book()}
    font-size: 13px;
    margin-bottom: 8px;

    a.tryit,
    a.tryit:active,
    a.tryit:hover,
    a.tryit:visited {
      ${heavy()}
      color: ${colors.trafficCone};
      font-size: 13px;
      margin-left: 4px;
    }

    a.tryit:hover {
      color: ${colors.trafficConeDark};
    }
  }

  button.comment-again,
  button.comment-again:active,
  button.comment-again:focus,
  button.comment-again:hover,
  button.comment-again:target {
    ${roman()}
    background: transparent;
    border: none;
    color: ${colors.boulder};
    font-size: 10px;
    outline: none;
    text-decoration: underline;
  }

  button.comment-again:active,
  button.comment-again:hover {
    cursor: pointer;
    color: ${colors.battleship};
  }

  @media screen and (max-width: 767px) {
    padding-top: 11px;
    padding-bottom: 10px;

    &.cta-false {
      padding-top: 17px;
      padding-bottom: 19px;
    }

    .comment-cta-title {
      font-size: 11px;
      margin-bottom: 4px;
    }

    .comment-cta-message {
      font-size: 10px;
      margin-bottom: 5px;

      a.tryit {
        font-size: 10px;
      }
    }

    button.comment-again {
      font-size: 9px;
    }
  }
`;
