import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from '@bblabs/module-library/atoms';
import styled from '@emotion/styled';
import { Environment } from '@bblabs/module-library/constants';
import EmailReactions from '@/components/EmailReactions/EmailReactions';

export type EmailLandingProps = {
  className?: string;
  clientId: string;
  commentReactionUrl: string;
  environment: Uppercase<Environment>;
  firstName?: string | null;
  jobId: string;
  likeReactionUrl: string;
  reactionsEnabled?: boolean;
  videoId: string;
  videoPlayerId: string;
  videoTranscriptUrl?: string | null;
  videoViewId?: string | null;
};

const EmailLanding = ({
  className = '',
  clientId,
  commentReactionUrl,
  environment,
  firstName = null,
  jobId,
  likeReactionUrl,
  videoId,
  videoPlayerId,
  videoTranscriptUrl = null,
  videoViewId = null,
  reactionsEnabled = true,
}: EmailLandingProps) => {
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState<number | null>(null);
  const [videoEmbedUrl, setVideoEmbedUrl] = useState<string | null>(null);

  const appendDiv = useCallback(
    (iframe: HTMLIFrameElement) => {
      const emptyDiv = document.createElement('div');
      emptyDiv.classList.add('empty-video-wrapper');
      emptyDiv.dataset.videoPlayerId = videoPlayerId;
      emptyDiv.style.position = 'absolute';
      emptyDiv.style.top = '0';
      emptyDiv.style.left = '0';
      emptyDiv.style.bottom = '0';
      emptyDiv.style.right = '0';
      iframe.parentElement?.appendChild(emptyDiv);

      emptyDiv.addEventListener('click', (e) => {
        e.preventDefault();
        setShowModal(true);
      });
    },
    [videoPlayerId],
  );

  useEffect(() => {
    const iframe: HTMLIFrameElement | null = document.querySelector(
      `iframe[data-video-player-id="${videoPlayerId}"]`,
    );
    if (iframe) {
      const updatedUrl = new URL(iframe.src);
      // ignore the aspect ratio for modals
      updatedUrl.searchParams.set('ar', '1');
      setVideoEmbedUrl(updatedUrl.toString());

      const iframeWidth = parseFloat(window.getComputedStyle(iframe).width);
      setWidth(iframeWidth);

      if (iframeWidth < 320) {
        appendDiv(iframe);
      }
    }
    function onDocumentClick(event: MouseEvent) {
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const modal = document.querySelector(
        `[role=dialog][data-video-player-id="${videoPlayerId}"]`,
      );
      const matchesWrapper = event.target.matches(
        `.empty-video-wrapper[data-video-player-id="${videoPlayerId}"]`,
      );

      const closeTranscript =
        event.target.querySelector('title')?.textContent === 'Close transcript';
      const isTranscriptButtons = event.target.textContent === 'Transcript' || closeTranscript;

      if (!modal || matchesWrapper || isTranscriptButtons) {
        return;
      }

      if (!event.target.closest('.modal-body')) {
        setShowModal(false);
      }
    }

    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [appendDiv, videoPlayerId]);

  const renderModal = () => {
    if (videoEmbedUrl === null) {
      return null;
    }
    return (
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        onClickout={() => setShowModal(false)}
        data-video-player-id={videoPlayerId}
        className={className}
      >
        <div className="vep-video-wrapper">
          <iframe
            data-video-player-id={videoPlayerId}
            className="vep-embedded-video"
            title="Video"
            src={videoEmbedUrl}
            allow="fullscreen"
          />
        </div>
        <EmailReactions
          clientId={clientId}
          commentReactionUrl={commentReactionUrl}
          environment={environment}
          firstName={firstName ?? ''}
          jobId={jobId}
          likeReactionUrl={likeReactionUrl}
          reactionsEnabled={reactionsEnabled}
          videoId={videoId}
          videoPlayerId={videoPlayerId}
          smallTranscriptBtn
          videoTranscriptUrl={videoTranscriptUrl ?? ''}
          videoViewId={videoViewId ?? ''}
        />
      </Modal>
    );
  };

  if (!width) {
    return null;
  }

  return (
    <div className={className}>
      {width < 320 && renderModal()}
      {width > 320 && (
        <EmailReactions
          clientId={clientId}
          commentReactionUrl={commentReactionUrl}
          environment={environment}
          firstName={firstName ?? ''}
          jobId={jobId}
          likeReactionUrl={likeReactionUrl}
          reactionsEnabled={reactionsEnabled}
          smallTranscriptBtn={width < 400}
          videoId={videoId}
          videoPlayerId={videoPlayerId}
          videoTranscriptUrl={videoTranscriptUrl ?? ''}
          videoViewId={videoViewId ?? ''}
        />
      )}
    </div>
  );
};

const durationIn = 350;
const durationOut = 200;
const easeIn = 'cubic-bezier(.65,0,.35,1)';
const easeOut = easeIn;

export default styled(EmailLanding)`
  /* This fires as soon as the element enters the dom */
  .modal-transition-enter .modal,
  .modal-transition-appear .modal {
    transform: translate(0, -50%);
    opacity: 0;
  }

  /* This is where we can add the transition*/
  .modal-transition-enter-active .modal,
  .modal-transition-appear-active .modal {
    transition: all ${durationIn}ms ${easeIn};
    opacity: 1;
    transform: translate(0, 0);
  }

  /* This fires as soon as the this.state.showList is false */
  .modal-transition-exit .modal {
    transition: all ${durationOut}ms ${easeOut};
    transform: translate(0, 0);
    opacity: 1;
  }

  /* fires as element leaves the DOM*/
  .modal-transition-exit-active .modal {
    transform: translate(0, -50%);
    opacity: 0;
  }

  .modal {
    width: 100%;
    transform: translate(0, 0);
    top: 0;
    left: 0;
    margin: 0;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // Fixes the 'Privacy' button overlapping
    z-index: 1;
    .modal-controls {
      background-color: white;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 1em 1em 1em 1em;
      height: auto;
      .modal-close {
        path {
          fill: #a0a0a0;
        }
        margin: 0;
        padding: 0;
      }
    }
    .modal-content {
      border-radius: 0;
      border: none;
      height: 100%;
      max-height: 100%;
    }
    .vep-video-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 56.25%;
    }
    .vep-embedded-video {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      border: none;
    }
  }
`;
