import { useEffect, useState } from 'react';
import { formatTranscriptAsTimeBlocks } from '@/functions/transcripts';

export type TranscriptItem = {
  start_time: number;
  end_time: number;
  entries: {
    start_time: number;
    end_time: number;
    content: string;
  }[];
};

export type Transcript = TranscriptItem[];

export type TranscriptResponseItem = {
  id: number;
  alternatives: { confidence: string; content: string }[];
} & ({ type: 'punctuation' } | { type: 'pronunciation'; start_time: number; end_time: number });

export type TranscriptResponse = {
  jobName: string;
  accountId: string;
  status: string;
  results: {
    transcripts: { transcript: string }[];
    language_codes: { language_code: string; duration_id_seconds: number }[];
    items: TranscriptResponseItem[];
  };
};

/**
 * Download the transcript
 */
async function getTranscript(transcriptUrl: string | null): Promise<Transcript> {
  if (!transcriptUrl) {
    return [];
  }
  try {
    const response = await fetch(transcriptUrl);
    if (!response.ok) {
      return [];
    }
    const data = (await response.json()) as TranscriptResponse;
    const formattedPieces = formatTranscriptAsTimeBlocks(data.results.items);
    return formattedPieces;
  } catch (error) {
    console.warn('Could not parse transcript', error);
    return [];
  }
}

/**
 * Load transcripts
 */
export default function useTranscripts(transcriptUrl: string | null) {
  const [transcript, setTranscript] = useState<Transcript>([]);
  const [status, setStatus] = useState<'ready' | 'loading'>(transcriptUrl ? 'loading' : 'ready');

  useEffect(() => {
    getTranscript(transcriptUrl).then((transcriptText) => {
      setStatus('ready');
      setTranscript(transcriptText);
    });
  }, [transcriptUrl]);
  // TODO: ^ this can cause the transcript tab to delay showing up, but
  // allows us to change the transcript URLs for overrides.

  return { transcript, status, setTranscript };
}
