import React from 'react';
import { colors } from '@bblabs/module-library/styles';

interface TranscriptSVGProps {
  fill?: string;
}

const TranscriptSVG = ({ fill = colors.battleship }: TranscriptSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.06 8.39">
    <path
      fill={fill}
      d="M14.31,1.5H8.25A.76.76,0,0,1,7.5.75.76.76,0,0,1,8.25,0h6.06a.76.76,0,0,1,.75.75A.76.76,0,0,1,14.31,1.5Z"
    />
    <path fill={fill} d="M9.4,8.39H.75a.75.75,0,0,1,0-1.5H9.4a.75.75,0,0,1,0,1.5Z" />
    <path
      fill={fill}
      d="M5.49,1.5H.75A.76.76,0,0,1,0,.75.76.76,0,0,1,.75,0H5.49a.76.76,0,0,1,.75.75A.76.76,0,0,1,5.49,1.5Z"
    />
    <path fill={fill} d="M14.31,4.94H.75a.75.75,0,0,1,0-1.5H14.31a.75.75,0,0,1,0,1.5Z" />
  </svg>
);

export default TranscriptSVG;
