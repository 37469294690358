import React, { useEffect, useRef, useState, useContext, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { colors, book, medium, heavy } from '@bblabs/module-library/styles';
import { Button } from '@bblabs/module-library/atoms';
import classNames from 'classnames';
import { MetroContext } from '@bblabs/module-library/classes';
import EmailReactionsContext from '../context';
import CommentConfirmation from '@/components/CommentConfirmation';
import Yardstick from '@/classes/Yardstick';

const SESSION_STORAGE = 'bbcomment';

type CommentProps = {
  className?: string;
  commentIsCollapsed?: boolean;
  onCommentSuccess?: () => void;
  setCommentIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
  setUseCommentFocusMode?: React.Dispatch<React.SetStateAction<boolean>>;
  videoTimestamp?: number;
};

function Comment({
  className = '',
  commentIsCollapsed = true,
  onCommentSuccess = () => {},
  setCommentIsCollapsed = () => {},
  setUseCommentFocusMode = () => {},
  videoTimestamp = 0,
}: CommentProps) {
  const {
    clientId,
    commentReactionUrl,
    environment,
    firstName,
    useCommentFocusMode,
    videoTranscriptUrl,
    videoViewId,
  } = useContext(EmailReactionsContext);

  type CommentMetricNames = {
    commentButtonClick: string;
    commentSendWithoutName: string;
  };

  const { sendMetric, MetricNames } = useContext(MetroContext) as unknown as {
    sendMetric: CallableFunction;
    MetricNames: CommentMetricNames;
  };

  const commentField = useRef<HTMLTextAreaElement>(null);

  const [commentStatus, setCommentStatus] = useState<'loading' | 'success' | 'ready'>('ready');
  const [sent, setSent] = useState(false);
  const storageKey = useMemo(() => `${SESSION_STORAGE}-${videoViewId}`, [videoViewId]);
  const [comment, setComment] = useState(sessionStorage.getItem(storageKey) ?? '');

  useEffect(() => {
    sessionStorage.setItem(storageKey, comment);
  }, [comment, storageKey]);

  const yardstick = Yardstick.forFeature('reaction', environment);

  /**
   * Send the comment
   */
  const sendComment = useCallback(async () => {
    yardstick.increment('comment.below_player.clicked');
    const commentText = commentField.current!.value;
    if (!commentText.length) {
      return;
    }

    yardstick.increment('comment.below_player.sent');
    setCommentStatus('loading');
    try {
      const query = new URLSearchParams({
        clientId,
        videoViewId,
        message: commentText,
        name: '',
        ts: String(Math.round(videoTimestamp)),
      });
      const response = await fetch(`${commentReactionUrl}?${query.toString()}`, { method: 'post' });
      if (!response.ok) {
        yardstick.increment('comment.below_player.failed');
        console.error('comment send failed', await response.text());
        return;
      }
      yardstick.increment('comment.below_player.success');
      sendMetric(MetricNames.commentButtonClick);
      sendMetric(MetricNames.commentSendWithoutName);
      setCommentStatus('success');
      setSent(true);
      onCommentSuccess();
      sessionStorage.removeItem(storageKey);
      setComment('');
    } catch (err) {
      console.error(err);
    }
  }, [
    MetricNames,
    clientId,
    commentReactionUrl,
    onCommentSuccess,
    sendMetric,
    storageKey,
    videoTimestamp,
    videoViewId,
    yardstick,
  ]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        sendComment();
      }}
    >
      {sent && (
        <CommentConfirmation
          className={`comment-confirmation ${className}`}
          onCommentAgain={() => {
            setSent(false);
            setCommentStatus('ready');
          }}
          ctaEnabled={false}
        />
      )}
      {!sent && (
        <div
          className={classNames(className, {
            'no-transcript': videoTranscriptUrl === null,
            'comment-focus-mode': useCommentFocusMode,
          })}
        >
          <div className={classNames('comment-wrapper')}>
            {/* this click handler is so we can focus the actual textbox */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div
              className={classNames('comment-input-wrapper', { collapsed: commentIsCollapsed })}
              onClick={() => commentField.current?.focus()}
            >
              <textarea
                className="comment-input"
                minLength={1}
                maxLength={1900}
                required
                name="comment"
                placeholder={firstName ? `Leave ${firstName} a reply` : 'Leave a reply'}
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                onFocus={() => {
                  setCommentIsCollapsed(false);
                  const textboxWidth = commentField.current?.offsetWidth ?? 0;
                  if (textboxWidth < 480) {
                    setUseCommentFocusMode(true);
                  }
                }}
                ref={commentField}
              />
            </div>
            {!commentIsCollapsed && useCommentFocusMode && (
              <Button
                type="button"
                label="Cancel"
                rectangle
                color={colors.silverSand}
                onClick={() => {
                  setCommentIsCollapsed(true);
                  setUseCommentFocusMode(false);
                }}
              />
            )}
            {!commentIsCollapsed && (
              <Button
                disabled={commentStatus !== 'ready'}
                type="submit"
                label="Send Reply"
                rectangle
                color={colors.taosSky}
                onClick={sendComment}
              />
            )}
          </div>
        </div>
      )}
    </form>
  );
}

export default styled(Comment)`
  position: relative;
  flex-grow: 1;
  /* 8px = magic number to keep 15px between thumb & comment */
  margin: 0 15px 0 8px;

  &.comment-focus-mode {
    margin-right: 0;
  }

  &.comment-confirmation {
    width: inherit;
    height: inherit;
  }

  > .comment-wrapper {
    text-align: right;

    > .comment-input-wrapper {
      --comment-border-radius: 3px;
      text-align: left;
      border: 0.1rem solid var(--reaction-border-color);
      border-radius: var(--comment-border-radius);
      margin-block-end: 11px;
      // some browsers will have a textarea that extends over the bottom border of the wrapper
      // this will keep it from getting cut off
      padding-block-end: 1px;
      padding-inline-start: 5px;
      height: 82px;
      cursor: text;

      &.collapsed {
        margin-bottom: 0;
        height: var(--collapsed-height);

        .comment-input {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
      }

      &:focus-within,
      &:hover {
        border-color: ${colors.taosSky};
        box-shadow: 0px 0px 0px 3px ${colors.taosSkyLightest};
      }
    }

    .comment-input {
      ${book()}
      resize: none;
      border: none;
      height: 100%;
      width: 100%;
      padding: 11px 14px;

      &::placeholder {
        ${medium()};
        color: ${colors.battleship};
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: none;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${colors.silverSandAlt};
        border-radius: 6px;
      }

      &:focus {
        outline: none;
      }

      &:focus::placeholder {
        color: ${colors.silverSand};
      }

      &:placeholder-shown {
        text-overflow: ellipsis;
      }

      &::placeholder {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    > button {
      ${heavy()};
      font-size: 12px;
    }

    /* cancel button + send reply */
    > button + button {
      margin-left: 11px;
    }
  }

  &.no-transcript {
    margin-right: 0;
    > .comment-wrapper {
      width: 100%;
      .comment-input-wrapper {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    margin-left: 0px;
  }
`;
