import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  MetroEvent,
  MetroContext,
  MetroHelper,
  MetroUser,
  metroContextDefaultProperties,
} from '@bblabs/module-library/classes';
import EmailLanding, { EmailLandingProps } from './EmailLanding';
import { autoInjectReactApp } from '@/helpers/documentIsReady';
import { EmailReactionMetricNames } from '@/components/EmailReactions/context';

type SubscriptionDetails = {
  subscriptionId: string;
  subscriptionStatus: string;
};

autoInjectReactApp(
  'EmailLanding',
  function injectEmailLanding(
    container: HTMLElement,
    props: (EmailLandingProps & SubscriptionDetails) | null,
  ) {
    if (props === null) {
      console.error('Could not find EmailLanding props');
      return;
    }

    const { subscriptionId, subscriptionStatus, environment, clientId } = props;
    const emptyEmailAddress = '';
    const metroUser = new MetroUser(
      props.clientId,
      emptyEmailAddress,
      subscriptionId,
      subscriptionStatus,
      '',
    );

    const existingSessionId = sessionStorage.getItem('email-session');
    const sessionId = existingSessionId ?? Math.random().toString(36).slice(2);
    sessionStorage.setItem('email-session', sessionId);
    const metroHelper = new MetroHelper('webapp', environment, metroUser, sessionId);

    const sendMetric = (videoPageEvent: string) => {
      const emptyUnit = null;
      const emptyValue = null;
      const metroEvent = new MetroEvent(videoPageEvent, emptyUnit, emptyValue, props.videoId);
      metroHelper.sendMetrics([metroEvent]);
    };

    const initialMetroObject = {
      ...metroContextDefaultProperties,
      appName: 'webapp',
      componentName: 'email_page',
      clientId,
      subscriptionId,
      subscriptionStatus,
      sessionId,
      sendMetric,
      MetricNames: EmailReactionMetricNames,
    };

    const root = createRoot(container);
    root.render(
      <MetroContext.Provider value={initialMetroObject}>
        <EmailLanding {...props} />
      </MetroContext.Provider>,
    );
  },
);
