import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Close } from '@bblabs/module-library/svgs';
import { LoadingDots } from '@bblabs/module-library/atoms';
import { colors, heavy } from '@bblabs/module-library/styles';
import { useEmailReactionsMetroContext } from '../context';
import Transcript from '@/components/Transcript';
import type { Transcript as TranscriptType } from '@/hooks/useTranscripts';

type TranscriptPanelProps = {
  className?: string;
  currentVideoTime: number;
  onClose: VoidFunction;
  transcript: TranscriptType;
  videoPlayerId: string;
  status: 'ready' | 'loading';
};

const TranscriptPanel = ({
  className = '',
  currentVideoTime,
  onClose,
  transcript,
  status,
  videoPlayerId,
}: TranscriptPanelProps) => {
  const { sendMetric, MetricNames } = useEmailReactionsMetroContext();
  const [loading, setLoading] = useState(true);

  function onCloseClick() {
    sendMetric(MetricNames.transcriptsClose);
    onClose();
  }

  useEffect(() => {
    if (status === 'ready') {
      setLoading(false);
    }
  }, [status]);

  return (
    <section className={`email-transcripts ${className}`}>
      <h1>Transcript</h1>

      <button type="button" onClick={onCloseClick}>
        <Close title="Close transcript" />
      </button>

      {loading && <LoadingDots />}
      {!loading && (
        <div className="container">
          <Transcript
            videoPlayerId={videoPlayerId}
            transcript={transcript}
            currentVideoTime={currentVideoTime}
          />
        </div>
      )}
    </section>
  );
};

export default styled(TranscriptPanel)`
  position: relative;
  --button-size: 11px;
  text-align: left;
  height: 200px;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  margin-left: 20px;

  > h1 {
    ${heavy()};
    font-size: 18px;
    margin: 12px 0;
  }
  > button {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: calc(var(--button-size) / 2);
    border: none;
    background: none;
    width: var(--button-size);
    height: var(--button-size);

    > svg {
      width: var(--button-size);
      height: var(--button-size);

      path {
        fill: ${colors.battleship};
      }
    }
  }

  > .container {
    overflow-y: auto;
    height: calc(100% - 49px);

    td {
      font-size: 14px;
      line-height: 19px;
    }
  }
`;
