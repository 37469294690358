import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Button } from '@bblabs/module-library/atoms';
import { medium, colors } from '@bblabs/module-library/styles';
import classNames from 'classnames';
import EmailReactionsContext, { useEmailReactionsMetroContext } from '../context';
import TranscriptSVG from './TranscriptSVG';

type TranscriptButtonProps = {
  className?: string;
  smallTranscriptBtn?: boolean;
  onClick: VoidFunction;
};

function TranscriptButton({
  className = '',
  onClick,
  smallTranscriptBtn = false,
}: TranscriptButtonProps) {
  const { useCommentFocusMode } = useContext(EmailReactionsContext);
  const { sendMetric, MetricNames } = useEmailReactionsMetroContext();
  return (
    <Button
      className={classNames(className, { 'comment-focus-mode': useCommentFocusMode })}
      label={smallTranscriptBtn ? '' : 'Transcript'}
      icon={<TranscriptSVG />}
      outline
      rectangle
      onClick={() => {
        onClick();
        sendMetric(MetricNames.transcriptsOpen);
      }}
    />
  );
}

export default styled(TranscriptButton)`
  position: relative;
  display: grid;
  grid-template-columns: 14px 1fr;
  align-content: center;
  align-items: center;
  height: var(--collapsed-height);
  ${medium};
  color: ${colors.battleship};
  font-size: 14px;
  border: 1px solid var(--reaction-border-color);
  ${({ smallTranscriptBtn = false }) =>
    smallTranscriptBtn &&
    `
    width: 47px;
    grid-template-columns: 1fr;
    justify-content: center;
  `};

  &.comment-focus-mode {
    display: none;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    outline: none;
    color: ${colors.battleship};
    background: ${colors.white};
    border-color: ${colors.taosSky};
    box-shadow: 0px 0px 0px 3px ${colors.taosSkyLightest};
  }

  > svg {
    position: relative;
    width: 14px;
    height: 8px;
    top: 1px;
    margin-left: ${({ smallTranscriptBtn = false }) => (smallTranscriptBtn ? '0' : '7px')};

    @media screen and (max-width: 480px) {
      margin-left: 0;
    }
  }

  > span {
    margin-left: 6px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 1rem;
  }
`;
